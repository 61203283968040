@import "utils";

.featuresList {
  padding: rem-calc(16);

  border-radius: rem-calc(16);
  border: 1px solid $c-gray-15;
}

.coursesList {
  display: flex;
  flex-direction: column;

  gap: rem-calc(4);
}

.courseLink {
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: rem-calc(8);

  padding: rem-calc(12 0);

  @include active {
    color: $c-red;
  }
}

.menuContentItem {
  transform: scale(0.8) translateY(20px);
  opacity: 0;

  animation: menuContentItemAnimation $duration $easing var(--delay, 0s) 1 forwards;
}

@keyframes menuContentItemAnimation {
  0% {
    transform: scale(0.8) translateY(20px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
