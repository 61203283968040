@import 'utils';

.container {
  display: flex;
  padding: rem-calc(12);
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid getColor($c-border);
  background: $c-white;
  background: getColor($c-bg-neutral);
  flex-shrink: 1;
  width: rem-calc(50);
  height: rem-calc(50);

  box-shadow: 0 4px 12px 0px rgba(28, 45, 64, 0.06);
}
