@import "utils";

.logoLink {
  flex-shrink: 0;
  transition: color $duration $easing;

  &:hover {
    color: $c-red;
  }
}
