@import "utils";

.coursesList {
  display: flex;
  gap: rem-calc(16);
  flex-wrap: wrap;

  @include breakpoint(small down) {
    gap: rem-calc(4 16);
  }
}

.coursesListItem {
  @include breakpoint(small down) {
    width: calc(50% - #{rem-calc(8)});
  }
}
