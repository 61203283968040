@import "utils";

.tableHeader {
  @include text-platform-h5;

  background-color: getColor($c-bg-neutral-tertiary);
  color: getColor($c-text-tertiary);
  vertical-align: middle;
  padding: rem-calc(14 12);

  &:first-child {
    border-bottom-left-radius: rem-calc(8);
    border-top-left-radius: rem-calc(8);
    padding: rem-calc(14 12 14 24);
  }

  &:last-child {
    border-bottom-right-radius: rem-calc(8);
    border-top-right-radius: rem-calc(8);
    padding: rem-calc(14 24 14 12);
  }
}
