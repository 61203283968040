@import "utils";

.logoutButton {
  position: absolute;
  top: 100%;
  z-index: 2;

  @include breakpoint(small down) {
    @include absolute-center-y;
    right: 0;
  }
}


