@import "utils";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  min-height: rem-calc(80);
  padding: rem-calc(16);
  gap: rem-calc(16);

  border: 1px solid getColor($c-border);
  border-radius: 12px;

  @include breakpoint(medium down) {
    align-items: flex-start;
    gap: rem-calc(16);
  }
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  gap: rem-calc(12);

  @include breakpoint(large down) {
    gap: rem-calc(16);
  }

  @include breakpoint(medium down) {
    width: 100%;
  }
}

.paymentStepTitle {
  display: flex;
  align-items: center;
  gap: rem-calc(12);

  order: 1;
}

.bottomContent {
  width: 100%;
  order: 3;

  @include breakpoint(large down) {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    align-self: flex-start;
  }
}

.deadlineBlock {
  display: flex;
  align-items: center;
  gap: rem-calc(16);
  order: 2;
  flex-grow: 1;
  justify-content: flex-end;
}

.progressCircle {
  display: flex;
  align-items: center;
  gap: rem-calc(8);
}

.divider {
  width: rem-calc(1);
  height: rem-calc(24);
  background-color: getColor($c-border);

  @include breakpoint(large down) {
    display: none;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: rem-calc(16);
}

.ctaContainer {
  width: max-content;
  order: 2;

  @include breakpoint(large down) {
    width: 100%;
  }
}

.cta {
  flex-grow: 1;
}

.completedStepIcon {
  color: getColor($c-icon-success);
}

.stepInProgressIcon {
  color: getColor($c-switch-fill);
}

