@import "utils";

$icon-size: 24px;
$button-padding: rem-calc(8);
$border-width: 1px;
$modal-width: 360px;
$button-width: calc(($icon-size + $button-padding + $border-width) * 2);
$modal-centering: calc(($button-width - $modal-width) / 2);

.languageSelector {
  padding: $button-padding;
}

.icon {
  width: $icon-size;
  height: $icon-size;
  border-radius: 100%;
  background-color: $c-gray-10;
  box-shadow: 0px 0px 1px 0px rgba(66, 71, 76, 0.56);
  overflow: hidden;
}

.modal {
  width: $modal-width;
  padding: rem-calc(8);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: rem-calc(4);
  border: 1px solid #EBEEF5;
  background-color: $c-white;

  position: absolute;
  top: calc(100% - 6px);
  z-index: 3;

  @extend %box-shadow;

  opacity: 0;
  visibility: hidden;
  transform: translate($modal-centering, -10px);
  transition:
    transform $duration $easing,
    opacity $duration $easing,
    visibility 0s $duration;

  @include dropdown-opened {
    opacity: 1;
    visibility: visible;
    transform: translate($modal-centering, 0);
    transition-delay: 0s;
  }
}

.domainSelectorItem {
  display: flex;
  align-items: center;
  padding: rem-calc(12 16);
  gap: rem-calc(10);
  border-radius: rem-calc(8);
  user-select: none;
  cursor: pointer;

  transition: background-color, $duration $easing;

  &:hover {
    background-color: $c-gray-10;
  }
}

.selectedItem {
  background-color: $c-gray-5;
  border: 1px solid #EBEEF5;
}

.languageButtonContainer {
  margin-left: auto;
  display: flex;
  gap: 2px;
}

.languageButton {
  background-color: transparent;
  color: #939BAE;
  font-weight: normal;
  border: 1px solid transparent;
  transition: background-color, $duration $easing;
  border-radius: rem-calc(8);
  padding: rem-calc(6 8);

  &:hover {
    background-color: #EBEEF5;
    border: 1px solid #EBEEF5;
  }
}

.selectedLanguage {
  background-color: $c-white;
  border: 1px solid #EBEEF5;
}
