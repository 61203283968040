@import 'utils';

.sidebar {
  overscroll-behavior: contain;
  z-index: 4;
}

.sidebarContent {
  z-index: 5;
}
