@import "utils";

.pageWrapper {
  background-color: getColor($c-bg-page-secondary);
  color: getColor($c-text-default);

  min-height: 100dvh;

  &.white {
    background-color: getColor($c-bg-page);
  }
}

.pageContent {
  padding-top: var(#{$header-height});

  @include breakpoint(large) {
    padding-left: var(#{$sidebar-width});
  }

  &.fillHeaderSpace {
    @include breakpoint(medium down) {
      padding-top: 0;
    }
  }

  &.withMobileAppMiniBanner {
    padding-top: calc(2 * var(--header-height));
  }
}
