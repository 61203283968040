@import "utils";

.table {
  width: 100%;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

@for $i from 1 through 16 {
  .width-#{$i} {
    width : #{100% / 16 * $i};
  }
}

.tableRowPlaceholder {
  border-bottom: 1px solid getColor($c-border);
}

.tableCellPlaceholder {
  border-radius: rem-calc(8);
  background-color: getColor($c-bg-neutral-tertiary);
  height: rem-calc(32);
  @include placeholderAnimation;
}
