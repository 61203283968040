@import "utils";

@mixin menu-opened {
  .menuOpened & {
    @content;
  }
}

@mixin menu-closed {
  .menuClosed & {
    @content
  }
}

.triggerButton {
  margin-right: rem-calc(-12);
}

.iconWrapper {
  position: relative;
  width: rem-calc(24);
  height: rem-calc(24);

  @include icon-styles {
    @include covers-parent;

    transition: transform $duration $easing, opacity $duration $easing;
  }
}

.burgerIcon {
  @include menu-opened {
    opacity: 0;
    transform: skew(30deg) scale(0.2);
  }

  @include menu-closed {
    transition-delay: 0.1s;
  }
}

.closeIcon {
  @include menu-opened {
    transition-delay: 0.1s;
  }

  @include menu-closed {
    opacity: 0;
    transform: skew(-45deg) scale(0.2);
  }
}

.menuWrapper {
  position: fixed;
  top: var(#{$landing-header-height});
  left: 0;
  width: 100%;
  max-height: calc(100% - var(#{$landing-header-height}));
  overflow-y: auto;
  overscroll-behavior: contain;
  background-color: $c-white;
  transition:
    opacity $duration $easing,
    visibility 0s $duration;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.05);
  border-top: 1px solid $c-gray-10;

  @include menu-opened {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
}

.menuItem {
  position: relative;

  &:not(:first-child) {
    &::before {
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      position: absolute;
      background-color: $c-gray-10;
      content: '';
      transition: transform $duration $easing;
      transform: scaleX(0);

      @include menu-opened {
        transform: none;
        transition-delay: var(--delay, 0s);
      }
    }
  }
}

.menuContentItem {
  transition: transform $duration $easing, opacity $duration $easing;
  transform: scale(0.8) translateY(20px);
  opacity: 0;

  @include menu-opened {
    transform: none;
    transition-delay: var(--delay, 0s);
    opacity: 1;
  }
}

.linkButton {
  @include active {
    #{$c-button-text}: $c-red;
  }
}
