@import 'utils';

.paymentStatus {
  @include text-platform-h5;
  padding: rem-calc(3 8);
  border-radius: 20px;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: rem-calc(8);
  }

  &.new {
    color: getColor($c-text-accent-rose);
    background-color: getColor($c-bg-accent-magenta-secondary);
    border: 1px solid getColor($c-border-accent-magenta);
  }

  &.skipped,
  &.on_review {
    color: getColor($c-text-accent-purple);
    background-color: getColor($c-bg-accent-purple);
    border: 1px solid getColor($c-border-accent-purple);
  }

  &.under_3d_secure {
    color: getColor($c-text-info);
    background-color: getColor($c-bg-info-secondary);
    border: 1px solid getColor($c-border-info);
  }

  &.accepted {
    color: getColor($c-text-success);
    background-color: getColor($c-bg-success);
    border: 1px solid getColor($c-border-success);
  }

  &.failed,
  &.declined {
    color: getColor($c-text-danger);
    background-color: getColor($c-bg-danger);
    border: 1px solid getColor($c-border-danger);
  }
}

.paymentsTooltip {
  margin-left: rem-calc(8);

  right: rem-calc(-14);
  bottom: rem-calc(32);

  &::before {
    right: rem-calc(22);
  }
}

.paymentsTooltipIcon {
  color: getColor($c-icon-tertiary);

  tr:has(&:hover) {
    z-index: 3;
  }
}
