@import "utils";

.container {
  gap: rem-calc(8);
  padding: rem-calc(4);

  background-color: getColor($c-bg-neutral-tertiary);

  border: 1px solid getColor($c-border);
  border-radius: rem-calc(8);
  width: max-content;

  &--fullWidth {
    width: 100%;
  }
}

.tab {
  color: getColor($c-text-tertiary);
  cursor: pointer;
  padding: rem-calc(8);
  border-radius: rem-calc(4);
  padding: rem-calc(6 18);
  transition:
    color $duration $easing,
    background-color $duration $easing;

  &--fullWidth {
    display: inline-flex;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 50%;
  }
}

.activeTab {
  height: 100%;
  color: getColor($c-text-inverse);
  background-color: getColor($c-bg-inverse)
}
