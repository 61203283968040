@import "utils";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  gap: rem-calc(24);
  flex-basis: rem-calc(846);
  flex-grow: 1;

  @include breakpoint(medium down) {
    flex-basis: unset;
    justify-content: flex-start;
  }
}
