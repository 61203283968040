@import 'utils';

.feeText {
  @include text-platform-small;
  color: getColor($c-text-brand);
}

.link {
  color: getColor($c-text-info);
  font-weight: $font-weight_medium;

  &:hover {
    text-decoration: underline;
  }
}

.placeholder {
  margin-top: rem-calc(12);
}

.statusFilterHeader {
  display: flex;
  flex-direction: column;
}
