@import 'utils';

.filter {
  display: inline-flex;
  align-items: center;

  :global(.ma-select__value-container) {
    :global(.ma-select__placeholder),
    :global(.ma-select__single-value)
    {
      @include text-platform-h5;
    }
  }

  :global(.ma-select__menu) {
    width: 180px;
    @include text-platform-h5;
  }

  :global(.ma-select__control) {
    border: none;
    border-radius: 0;
    padding: 0;
    height: auto;
    min-height: auto;
  }

  :global(.ma-select__input) {
    input {
      max-width: 100px;
    }
  }
  :global(.ma-select__single-value) {
    padding: 0;
    position: static;
    transform: none;
  }
  :global(.ma-select__placeholder) {
    position: static;
    transform: none;
  }

}
