@import "utils";

.list {
  /* empty */
}

.listItem {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
