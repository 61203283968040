@import 'utils';

.progressBarWrapper {
  display: flex;
  align-items: center;
  border-radius: rem-calc(6);

  position: relative;

  &--small {
    height: rem-calc(4);
  }

  &--medium {
    height: rem-calc(8);
  }
}

.progressBarItem {
  flex-grow: 1;
  height: 100%;

  position: relative;

  &:first-child {
    border-top-left-radius: rem-calc(6);
    border-bottom-left-radius: rem-calc(6);
  }
  &:last-child {
    border-top-right-radius: rem-calc(6);
    border-bottom-right-radius: rem-calc(6);
    margin: 0;
  }

  &.dashed {
    margin-right: rem-calc(4);
  }
}

.progressBarSolid {
  height: 100%;
  border-radius: rem-calc(6);

  transition: width $duration $easing;
}
