@import "utils";

$custom-x-large-breakpoint: rem-calc(1280);

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $c-white;
  z-index: 4;
  padding-right: var(#{$scrollbar-width});
  border-bottom: 1px solid $c-gray-15;
}

.headerContainer {
  height: var(#{$landing-header-height});
  display: flex;
  align-items: center;
}

.pushRight {
  margin-left: auto;
}

.phoneNumber {
  font-size: rem-calc(14);
}

.hideOnXLarge {
  display: none;

  @include breakpoint($custom-x-large-breakpoint down) {
    display: block;
  }
}
