@import "utils";

.userIconWrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: rem-calc(4);
  border-radius: rem-calc(4);

  color: $c-headings;
  transition: background-color $duration $easing;

  transform-origin: right;

  &:hover {
    background-color: $c-gray-10;
  }
}

.authUserName {
  text-align: left;
  margin-left: rem-calc(16);

  @include elipsis();
}
