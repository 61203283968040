@import "utils";

.container {
  display: flex;
  gap: rem-calc(24);
  padding: rem-calc(40);

  @include breakpoint(medium down) {
    justify-content: center;
  }

  @include breakpoint(small down) {
    flex-direction: column;
    gap: rem-calc(16);
    padding: rem-calc(24);
  }
}

.mainContent {
  display: grid;
  width: 100%;
  gap: rem-calc(24);
  grid-template-columns: 1fr 10fr 1fr;

  @include breakpoint(medium down) {
    display: flex;
    flex-direction: column;
  }
}

.graduatedBlock {
  @extend %content-block;
  @extend %content-block-padding;

  overflow: hidden;
}
