@import "utils";

$custom-x-large-breakpoint: rem-calc(1280);

.linkButton {
  @include active {
    #{$c-button-text}: $c-red;
  }
}

.showOnXLarge {
  display: none;

  @include breakpoint($custom-x-large-breakpoint up) {
    display: block;
  }
}
