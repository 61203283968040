@import "utils";

.tableRow {
  border-bottom: 1px solid getColor($c-border);
  transition-property: background-color;
  transition-duration: $duration;
  transition-timing-function: $easing;

  // position relative doesn't work for <tr> but there are hack with scale(1) which makes it work
  transform: scale(1);
  position: relative;

  &:hover {
    background-color: getColor($c-fill-tertiary-hovered)
  }

  &:focus-within {
    z-index: 2;
  }

  @include breakpoint(medium down) {
    display: block;
    margin: rem-calc(0 -32 16);
    border-bottom: none;
  }
}

.disabled {
  border-color: getColor($c-border);
  color: getColor($c-text-disabled);
  cursor: not-allowed;
}
