@import "utils";

.containerWrapper {
  display: flex;
}

.childrenWrapper {
  --cta-children-indent: #{rem-calc(4)};

  transition: transform $duration $easing;

  @include breakpoint(medium) {
    margin-right: var(--cta-children-indent);
  }

  @include breakpoint(large) {
    --cta-children-indent: #{rem-calc(8)};
  }
}

.ctaWrapper {
  @include breakpoint(small down) {
    display: none;
  }
}
