@import "utils";

.circleWrapper {
  position: relative;
  max-width: 180px;
  pointer-events: none;

  :global(.CircularProgressbar) {
    width: 100%;
    vertical-align: middle;
  }

  :global(.CircularProgressbar) :global(.CircularProgressbar-path) {
    stroke-linecap: round;
    transition: stroke-dashoffset $duration $easing 0s;
  }

  :global(.CircularProgressbar) :global(.CircularProgressbar-trail) {
    stroke-linecap: round;
  }

  height: calc(var(--size) * 1px);
  width: calc(var(--size) * 1px);

  grid-area: circles;

  [data-test-id="CircularProgressbarWithChildren"] {
    height: calc(var(--size) * 1px);
    width: calc(var(--size) * 1px);
    position: relative;
  }

  [data-test-id="CircularProgressbarWithChildren__children"] {
    top: 0;
    left: 0;
    margin-top: 0!important;
  }
}

.loader {
  border-radius: 50%;
}
