@import "utils";

@mixin large {
  .featureLarge & {
    @content
  }
}

@mixin small {
  .featureSmall & {
    @content
  }
}

.feature {
  display: flex;
}

.featureIcon {
  @include large {
    --icon-size: #{rem-calc(24)};
    margin-top: rem-calc(3);
  }

  @include small {
    --icon-size: #{rem-calc(16)};
    margin-top: rem-calc(4);
  }
  width: var(--icon-size);
  height: var(--icon-size);
  color: $c-gray-40;
  margin-right: rem-calc(8);
  flex-shrink: 0;
}

.featureText {
  color: $c-gray-50;

  @include large {
    @include text-landing-main;
  }

  @include small {
    @include text-landing-main;
  }
}
