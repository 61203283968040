@import "utils";

.chubbsContainer {
  width: rem-calc(160);
  height: rem-calc(160);

  display: flex;
  align-items: flex-end;
  justify-content: center;

  border-radius: 50%;
  overflow: hidden;
  margin: rem-calc(0 auto 24px);

  &.brandSecondary {
    background-color: getColor($c-bg-brand-secondary);
  }

  &.accentPurpleSecondary {
    background-color: getColor($c-bg-accent-purple-secondary);
  }

  &.warningSecondary {
    background-color: getColor($c-bg-warning-secondary);
  }

  &.info {
    background-color: getColor($c-bg-info);
  }

  &.neutral {
    background-color: getColor($c-bg-neutral);
    border: 1px solid getColor($c-border);
  }
}
