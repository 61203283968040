@import 'utils';

.wrapper {
  position: relative;

  width: 100%;

  &:after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: getColor($c-border);
    opacity: 0.5;
    content: "";
  }

  @include breakpoint(large) {
    display: none;
  }
}

.dropdownContent {
  display: none;

  @include dropdown-opened {
    border-top: 1px solid getColor($c-border);

    display: block;
  }

  @include breakpoint(medium) {
    display: none;
  }
}
