@import "utils";

.container {
  display: flex;
  align-items: flex-start;
  gap: rem-calc(24);

  @include breakpoint(medium down) {
    flex-direction: column;
    gap: rem-calc(16);
  }
}

.content {
  background-color: getColor($c-bg-neutral);
  padding: rem-calc(24);
  border-radius: 16px;
}

.graduatedBlock {
  @extend %content-block;
  @extend %content-block-padding;

  overflow: hidden;
}
