@import "utils";

.container {
  background-color: getColor($c-bg-neutral);
  padding: rem-calc(16);
  border: 1px solid getColor($c-border);
  border-radius: rem-calc(16);

  height: max-content;
  min-width: rem-calc(200);

  @include breakpoint(medium down) {
    display: flex;
    align-items: center;
    height: auto;

    flex-grow: 1;
    min-width: unset;
    width: 100%;

    gap: rem-calc(8);
  }
}

.icon {
  height: rem-calc(24);
  width: rem-calc(24);

  &--success {
    color: getColor($c-icon-success);
  }

  &--purple {
    color: getColor($c-icon-accent-purple);
  }

  @include breakpoint(small down) {
    height: rem-calc(16);
    width: rem-calc(16);
  }
}

.iconContainer {
  width: max-content;

  padding: rem-calc(8);
  border-radius: rem-calc(8);
  margin-bottom: rem-calc(16);

  &--success {
    background-color: getColor($c-bg-success-secondary);
  }

  &--purple {
    background-color: getColor($c-bg-accent-purple-secondary);
  }

  @include breakpoint(small down) {
    padding: rem-calc(4);
    margin-bottom: 0;
  }
}

.title {
  color: getColor($c-text-secondary)
}

.number {
  @include text-platform-h1;

  @include breakpoint(medium down) {
    white-space: nowrap;
    @include text-platform-h3;
  }
}
