@import "utils";

.languageSelector {
  position: relative;
  min-height: rem-calc(50);
}

.inputsWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: rem-calc(16);

  @include breakpoint(small down) {
    gap: rem-calc(8)
  }
}

.label {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: rem-calc(12);

  border-radius: rem-calc(10);

  transition: border-color $duration, background-color $duration;

  :global(.inputDecoration) {
    display: none;
  }

  border: 1px solid getColor($c-border);
  background-color: getColor($c-fill-secondary);

  &:hover {
    background-color: getColor($c-fill-secondary-hovered);
    border-color: getColor($c-border-hovered);
  }

  @include breakpoint(small down) {
    width: 100%;
  }
}

.selected {
  border-color: getColor($c-border-pressed);

  &:hover {
    background-color: getColor($c-fill-secondary);
    border-color: getColor($c-border-pressed);
  }
}


