@import 'utils';

.proofOfRevenueStatus {
  @include text-platform-h5;
  padding: rem-calc(8 16);
  border-radius: 20px;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: rem-calc(8);
  }

  &.UPLOADED {
    color: getColor($c-text-accent-purple);
    background-color: getColor($c-bg-accent-purple-secondary);
  }

  &.ON_REVIEW {
    color: getColor($c-text-tertiary);
    background-color: getColor($c-bg-neutral-tertiary);
  }

  &.ACCEPTED {
    color: getColor($c-text-success);
    background-color: getColor($c-bg-success-secondary);
  }

  &.FAILED,
  &.DECLINED {
    color: getColor($c-text-brand);
    background-color: getColor($c-bg-brand-secondary);
  }
}
