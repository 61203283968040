@import "utils";

.sidebarItem {
  position: relative;

  display: flex;
  align-items: center;

  margin: rem-calc(8 4);
  padding: rem-calc(8 0);

  &:hover {
    background-color: getColor($c-fill-hovered);
    border-radius: 10px;
  }

  @include breakpoint($extraMediumDown) {
    padding: rem-calc(8 16);

    &:hover {
      background-color: transparent;
    }
  }

  @include breakpoint(large) {
    flex-direction: column;
  }

  :global(.icon) {
    width: 100%;
    height: 100%;
    transition: color $duration $easing;
  }

  &:hover :global(.icon) {
    color: getColor($c-icon);
  }

  &:hover > .itemText {
    color: getColor($c-text-default);
  }
}

.itemText {
  @include elipsis();
  flex-shrink: 0;
  transition: color $duration $easing;
  font-size: rem-calc(16);
  line-height: 1.2em;

  &::before {
    @include covers-parent;
    content: '';
  }

  @include breakpoint(large) {
    font-size: rem-calc(12);
  }
}

.disabled {
  pointer-events: none;
}

.itemIcon {
  flex-shrink: 0;
  position: relative;

  height: rem-calc(32);
  width: rem-calc(32);
  margin-right: rem-calc(16);

  color: getColor($c-icon-tertiary);
  transition: color $duration $easing;

  @include breakpoint(large) {
    width: rem-calc(20);
    height: rem-calc(20);

    margin-right: 0;
    margin-bottom: rem-calc(4);
  }

  &.accent::before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px solid getColor($c-bg-neutral);
    background-color: getColor($c-fill-brand-bold);
  }

  .notificationsCounter {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: -50%;
    left: 50%;

    width: max-content;
    min-width: rem-calc(20);
    height: rem-calc(20);

    padding: rem-calc(4);

    border-radius: rem-calc(10);
    border: 2px solid getColor($c-bg-neutral);

    color: getColor($c-text-bold);
    font-size: rem-calc(12);
    font-weight: 500;

    &.highlighted {
      background-color: getColor($c-fill-brand-bold);
    }

    &.common {
      background-color: getColor($c-bg-neutral-tertiary);
      color: getColor($c-text-tertiary);
    }
  }
}
