@import "utils";

.tableHead {
  @include breakpoint(medium down) {
    display: none;
  }

  tr {
    border-bottom: none;
  }
}
