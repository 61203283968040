@import "utils";

.progressBar {
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  gap: rem-calc(16);

  padding: rem-calc(16);
  border: 1px solid getColor($c-border);
  border-radius: 12px;
}
