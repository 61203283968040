@import "utils";

@mixin dropdown-opened {
  .dropdownOpened & {
    @content
  }
}

.triggerButton {
  @include icon-styles {
    transition: transform $duration $easing;

    @include dropdown-opened {
      transform: rotateX(-180deg);
    }
  }
}

.dropdownWrapper {
  position: fixed;
  top: var(#{$landing-header-height});
  left: 0;
  width: 100%;
  max-height: calc(100% - var(#{$landing-header-height}));
  overflow-y: auto;
  overscroll-behavior: contain;
  background-color: $c-white;
  transition:
      opacity $duration $easing,
      visibility 0s $duration;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.05);
  border-top: 1px solid $c-gray-10;

  @include dropdown-opened {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }

  @include breakpoint(medium down) {
    height: 100%;
  }
}

.dropdownItem {
  padding-top: rem-calc(32);
  padding-bottom: rem-calc(32);

  &:not(:first-child) {
    border-top: 1px solid $c-gray-10;
  }

  @include breakpoint(medium down) {
    padding-top: rem-calc(24);
    padding-bottom: rem-calc(24);
  }

  @include breakpoint(small down) {
    padding-top: rem-calc(16);
  }
}

.courseLink {
  @include active {
    #{$c-button-background}: $c-gray-10;
    #{$c-button-text}: $c-red;
  }
}

.coursesListItem {
  transition:
    transform $duration $easing,
    opacity $duration $easing;

  opacity: 0;
  transform: translateX(20px) scale(0.9);

  @include breakpoint(small down) {
    transform: translateY(20px) scale(0.9);
  }

  @include dropdown-opened {
    transition-delay: var(--delay, 0s);
    opacity: 1;
    transform: none;
  }
}
