@import "utils";

.card {
  align-self: stretch;

  @include breakpoint(medium down) {
    width: 100%;
  }
}

.iconCompleted {
  color: getColor($c-icon-success);
}

.toggleButton {
  @include icon-styles {
    transition: transform $duration $easing;
  }
}

.togglerActive {
  @include icon-styles {
    transform: rotateX(180deg);
  }
}
