@import "utils";

.container {
  position: relative;
  display: flex;
  border-bottom: 1px solid getColor($c-border);
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: rem-calc(16);
  align-items: flex-end;

  &::-webkit-scrollbar {
    display: none;
  }
}

.wrapper {
  @extend %button-reset;
  @include text-platform-h4;
  position: relative;
  opacity: 0.8;
  color: getColor($c-text-tertiary);
  transition-property: color, opacity;
  transition-duration: $duration;
  transition-timing-function: $easing;
  padding-bottom: rem-calc(12);
  margin: rem-calc(0 16);
  white-space: nowrap;

  @include withTabIndicator {
    background-color: transparent;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:not(.active):hover {
    opacity: 1;
    @include withTabIndicator {
      background-color: getColor($c-fill-secondary);
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
    @include withTabIndicator {
      background-color: $c-current;
    }
  }
}

.active {
  color: getColor($c-text-default);

  @include withTabIndicator {
    background-color: getColor($c-fill-inverse);
  }
}

.scrollable {
  width: auto;
  min-width: min-content;
  white-space: nowrap;
}

.compact {
  border: none;
  margin-bottom: 0;
}

.centered {
  justify-content: center;
}

.wide {
  padding-left: rem-calc(30);
  padding-right: rem-calc(30);
}
