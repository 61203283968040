@import 'utils';

.documentLink {
  a {
    color: getColor($c-text-info);
    font-weight: $font-weight_medium;

    &:hover {
      text-decoration: underline;
    }
  }
}
