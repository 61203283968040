@import 'utils';

.sidebarIcon {
  width: 100%;
  height: 100%;

  color: getColor($c-icon-tertiary);
}

.sidebarOverlay {
  @extend %covers-parent;

  position: fixed;
  z-index: 2;
  transform: translateZ(0);

  opacity: 0;
  visibility: hidden;
  background-color: $c-black;

  transition:
    opacity $duration $easing,
    visibility 0s $duration;

  &.overlayActive {
    opacity: .5;
    visibility: visible;
    transition-delay: 0s;
  }

  @include breakpoint(small down) {
    display: none;
  }
}

.mobileEditor {
  opacity: 0;
}

.sidebarWrapper {
  position: fixed;
  top: var(#{$header-height});
  left: 0;
  z-index: 5;

  @extend %full-height;
  width: var(#{$sidebar-width});
  pointer-events: none;
}

.sidebarNav {
  background-color: getColor($c-bg-neutral);
  border-right: 1px solid getColor($c-border);
  height: 100%;
  pointer-events: auto;
  overflow: hidden;
  transition: transform $duration $easing;

  @include breakpoint(medium down) {
    transform: translateX(-100%);
  }
}

.activeSidebarNav {
  transform: none;
}

.sidebarNavInner {
  height: 100%;
  overflow: hidden;
  transition: transform $duration $easing;

  @include breakpoint($extraMediumDown) {
    transform: translateX(100%) translateX(-60px);
  }
}

.activeSidebarNavInner {
  transform: none;
}

.sidebarScrollContainer {
  @extend %smooth-scroll;

  height: 100%;
}

.navList {
  overflow: hidden;
  flex-shrink: 0;
  color: getColor($c-text-tertiary);

  @include breakpoint(small down) {
    display: flex;
    flex-direction: column;
    padding: rem-calc(0 20 12);
  }
}

.navItem {
  @include breakpoint(medium down) {
    display: flex;
    flex-grow: 1;
    height: 72px;

    &:not(:last-child) {
      border-bottom: 1px solid getColor($c-border);
    }
  }
}

.activeSidebarItem {
  color: getColor($c-text-default);
  font-weight: $font-weight_extra-bold;

  &::after {
    opacity: 1;
  }

  :global(.icon) {
    color: getColor($c-icon);
  }
}
