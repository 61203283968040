@import "utils";

.widgets {
  display: flex;
  flex-direction: column;
  top: rem-calc(200);
  gap: rem-calc(16);

  @include breakpoint(medium down) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}
