@import "utils";

.container {
  @extend %flex-centered;

  max-height: rem-calc(432);
}

.outerCircle {
  @extend %flex-centered;
  position: relative;

  min-width: rem-calc(908);
  min-height: rem-calc(908);

  border: 1px solid getColor($c-border);
  border-radius: 50%;
}

.middleCircle {
  @extend %flex-centered;

  width: rem-calc(578);
  height: rem-calc(578);

  border: 1px solid getColor($c-border);
  border-radius: 50%;
}

.innerCircle {
  @extend %flex-centered;

  width: rem-calc(352);
  height: rem-calc(352);

  border: 1px solid getColor($c-border);
  border-radius: 50%;

  @include breakpoint(medium down) {
    width: rem-calc(440);
    height: rem-calc(440);
  }
}

.content {
  @extend %flex-centered;
  flex-direction: column;

  width: max-content;
}

.avatar {
  background-color: getColor($c-white);
  border: 2px solid getColor($c-border);
  border-radius: 50%;
  padding: rem-calc(4);
}

.title {
  color: getColor($c-text);
}

.subtitle {
  color: getColor($c-text-secondary);
}

.emoji {
  position: absolute;

  border-radius: 50%;
  background-color: getColor($c-bg-neutral-secondary);
  border: 1px solid getColor($c-border);

  // 🪄 Magic numbers alert 🪄

  &:nth-child(1) {
    top: 38%;
    right: 30%;

    @include breakpoint(medium down) {
      top: 28%;
      right: 33%;
    }
  }

  &:nth-child(2) {
    bottom: 30%;
    right: 21%;
  }

  &:nth-child(3) {
    bottom: 33%;
    left: 20%;

    @include breakpoint(medium down) {
      bottom: 28%;
      left: 33%;
    }
  }

  &:nth-child(4) {
    top: 38%;
    left: -1%;
  }

  &:nth-child(5) {
    top: 30%;
    right: 1%;
  }

  // 🪄 Magic numbers alert is over 🪄
}
